import { Plugins, PermissionType } from '@capacitor/core';
import { useDispatch } from 'react-redux';
import { loading, loaded } from '../../Action/api';

const { Geolocation, Permissions, Device } = Plugins;

export function useLocation() {
  const dispatch = useDispatch();

  const isLocationEnabled = async () => {
    const info = await Device.getInfo();

    if (info.platform === 'web') {
      if (!navigator.geolocation) {
        throw new Error('Geolocation is not supported by this browser');
      }

      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(position => {
          resolve(position);
        }, err => {
          reject(err);
        });
      });
    }

    return Permissions.query({
      name: PermissionType.Geolocation,
    }).then((res) => {
      if (res.state === 'denied') {
        throw new Error('Permission denied');
      }

      return true;
    });
  };

  const getCurrentPosition = async () => {
    try {
      dispatch(loading());

      const coordinates = await Geolocation.getCurrentPosition();

      dispatch(loaded());

      return coordinates;
    } catch (error) {
      dispatch(loaded());

      throw error.message;
    }
  };

  return { Geolocation, getCurrentPosition, isLocationEnabled };
}
