import { useDispatch } from 'react-redux';
import { showToast } from '../../Action/toast';

export function useToast() {
  const dispatch = useDispatch();

  const show = (message: string, color: string) => {
    dispatch(
      showToast({
        message: message,
        color,
      })
    );
  };

  const error = (message: string) => {
    show(message, 'danger');
  };

  const success = (message: string) => {
    show(message, 'success');
  };

  return { error, success, show };
}
