import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import "moment-timezone";

moment.locale('vi', {
  relativeTime: {
    future: ' %s',
    past: '%s trước',
    s: 'vừa xong',
    ss: '%d giấy',
    m: '1 phút',
    mm: '%d phút',
    h: '1 giờ',
    hh: '%d giờ',
    d: '1 ngày',
    dd: '%d ngày',
    M: '1 tháng',
    MM: '%d tháng',
    y: '1 năm',
    yy: '%d năm'
  }
});

export function useMoment() {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);

  return moment;
}
