import React from 'react';
import { IonToast, IonContent } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { hideToast } from '../../Action/toast';

const ToastMessage: React.FC = () => {
  const dispatch = useDispatch();
  const { list }: any = useSelector<any>(state => state.Toast);

  return (
    <IonContent>
      {list.map((toast: any) => (
        <IonToast
          key={toast.id}
          isOpen={toast.isOpen}
          onDidDismiss={() => dispatch(hideToast(toast.id))}
          position="top"
          color={toast.color || 'success'}
          message={toast.message}
          duration={toast.duration || 2000}
        />
      ))}
    </IonContent>
  );
};

export default ToastMessage;
