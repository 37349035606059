import { useDispatch } from 'react-redux';
import { showAlert } from '../../Action/alert';
import { AlertReducer } from '../../Reducer/alert';

export function useAlert() {
  const dispatch = useDispatch();

  const show = (options: AlertReducer) => {
    dispatch(showAlert(options));
  };

  const disabledLocationMessage = (err?: string) => {
    show({
      header: 'Location is Disabled',
      message: err || 'To enable location, go to your Settings',
      backdropDismiss: false,
      buttons: ['Ok'],
    });
  };

  return { show, disabledLocationMessage };
}
