import {
  Plugins,
  CameraResultType,
  CameraSource,
  CameraDirection,
  PermissionType,
} from '@capacitor/core';
import { useAlert } from './alert';

const { Camera, Permissions, Device } = Plugins;

const cameraOptions = {
  quality: 90,
  allowEditing: false,
  source: CameraSource.Camera,
  resultType: CameraResultType.DataUrl,
  correctOrientation: true,
  direction: CameraDirection.Rear,
};

export function useCamera() {
  const Alert = useAlert();

  const isCameraEnabled = async () => {
    const info = await Device.getInfo();

    if (info.platform === 'web') {
      return info;
    }

    return Permissions.query({
      name: PermissionType.Camera,
    }).then((res) => {
      if (res.state === 'denied') {
        throw new Error('Permission denied');
      }

      return true;
    });
  };

  const takePicture = async (options = {}) => {
    const image = await Camera.getPhoto({
      ...cameraOptions,
      ...options,
    });

    return image;
  };

  const permissionDenied = (err?: string) => {
    Alert.show({
      header: 'Camera is Disabled',
      message: err || 'To enable Camera, go to your Settings',
      backdropDismiss: false,
      buttons: ['OK'],
    });
  };

  return { takePicture, isCameraEnabled, permissionDenied };
}
