import {
  NETWORK_STATUS_CHANGE,
  QUEUE_STATUS_CHANGE,
  DeviceStore
} from '../Action/device';
import { QueueSqLite } from '../services/queueSqLite';

const initialState: DeviceStore = {
  network: {
    connected: false,
    connectionType: null,
    listener: null,
  },
  queue: {
    stopped: true,
    running: false,
    queue: null,
    provider: null,
  },
  sqLite: new QueueSqLite(),
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case NETWORK_STATUS_CHANGE:
      return {
        ...state,
        network: {
          ...state.network,
          ...action.network,
        },
      };
    case QUEUE_STATUS_CHANGE:
      state.sqLite.stopped = action.queue.stopped;

      return {
        ...state,
        queue: {
          ...state.queue,
          ...action.queue,
        },
      };
    default:
      return state;
  }
}
