import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Provider } from 'react-redux';
import Store from './Store';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import Pin from './pages/Pin/Pin';
import Auth from './pages/Auth/Auth';
import Photo from './pages/Photo/Photo';
import ToastMessage from './components/Toast';
import Alert from './components/Alert';
import './App.scss';
import './translations';

const App: React.FC = () => {
  return (
    <Provider store={Store}>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/auth" component={Auth} exact={true} />
            <Route path="/pin" component={Pin} exact />
            <Route path="/photo" component={Photo} exact />
            <Redirect exact from="/" to="/auth" />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>

      <ToastMessage></ToastMessage>
      <Alert></Alert>
    </Provider>
  );
};

export default App;
