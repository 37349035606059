import React from 'react';
import { IonContent, IonAlert } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlert } from '../../Action/alert';

const Alert: React.FC = () => {
  const dispatch = useDispatch();
  const {
    isShow,
    message,
    header,
    buttons,
    backdropDismiss,
  }: any = useSelector<any>((state) => state.Alert);

  return (
    <IonContent>
      <IonAlert
        isOpen={isShow}
        backdropDismiss={backdropDismiss}
        onDidDismiss={() => dispatch(hideAlert())}
        header={header}
        message={message}
        buttons={buttons ? buttons : ['OK']}
      />
    </IonContent>
  );
};

export default Alert;
