const initialState = {
  isLoading: false
};

export default function(state = initialState, action: any) {
  switch (action.type) {
    case 'LOADING':
      return {
        isLoading: true
      };
    case 'LOADED':
      return {
        isLoading: false
      };
    default:
      return state;
  }
}
