export type AlertReducer = {
  isShow?: boolean;
  header: string;
  message: string;
  buttons: any[];
  backdropDismiss?: boolean;
}

const initialState: AlertReducer = {
  isShow: false,
  header: '',
  message: '',
  buttons: [],
  backdropDismiss: true
};

export const Alert = (state = initialState, action: any) => {
  switch (action.type) {
    case 'SHOW_ALERT':
      return {
        isShow: true,
        header: action.header,
        message: action.message,
        buttons: action.buttons,
        backdropDismiss: action.backdropDismiss || true
      };
    case 'HIDE_ALERT':
      return {
        isShow: false,
        header: '',
        message: '',
        buttons: []
      };
    default:
      return state;
  }
}
