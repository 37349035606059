import { combineReducers } from 'redux';
import Toast from './toast';
import Api from './api';
import { Alert } from './alert';
import Device from './device';

export default combineReducers({
  Toast,
  Api,
  Alert,
  Device
});
