import { QueueSqLite } from "../services/queueSqLite";

export const NETWORK_STATUS_CHANGE = 'NETWORK_STATUS_CHANGE';
export const QUEUE_STATUS_CHANGE = 'QUEUE_STATUS_CHANGE';
export const START_QUEUE = 'START_QUEUE';
export const CREATE_QUEUE = 'CREATE_QUEUE';

export interface QueueRow {
  id: number;
  method: string;
  data: string;
  status: string;
  success: string;
  failed: string;
  timestamp: string;
}

export interface Queue {
  stopped?: boolean;
  running?: boolean;
  queue?: QueueRow | null;
  provider?: any;
  sqLite?: any;
}

export interface NetWork {
  connected?: boolean;
  connectionType?: any;
  listener?: any;
}

export interface DeviceStore {
  network: NetWork;
  queue: Queue;
  sqLite: QueueSqLite;
}

export const changeNetworkStatus = (network: NetWork) => {
  return {
    type: NETWORK_STATUS_CHANGE,
    network,
  };
};

export const changeQueueStatus = (queue: Queue) => {
  return {
    type: QUEUE_STATUS_CHANGE,
    queue,
  };
};

export const createQueue = (provider: any) => {
  return {
    type: CREATE_QUEUE,
    provider
  };
};
