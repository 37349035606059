import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

async function get(key: string) {
  const ret = await Storage.get({ key });

  if (ret.value === null) {
    return null;
  }

  return JSON.parse(ret.value);
}

async function set(key: string, value: any) {
  return await Storage.set({ key, value: JSON.stringify(value) });
}

async function remove(key: string) {
  return await Storage.remove({ key });
}

async function keys() {
  const { keys } = await Storage.keys();

  return keys;
}

async function clear() {
  return await Storage.clear();
}

export default { get, set, remove, keys, clear };
