import * as Api from '../../services/api';
import { useDispatch } from 'react-redux';
import { loading, loaded, showErrorMessage } from '../../Action/api';

export function useApi() {
  const dispatch = useDispatch();

  const get = async (endpoint: string, token: boolean = true) => {
    dispatch(loading());

    return Api.get(endpoint, token)
      .then((res) => success(res, dispatch))
      .catch((err) => catchError(err, dispatch));
  };

  const post = async (endpoint: string, data: any, token: boolean = true) => {
    dispatch(loading());

    return Api.post(endpoint, data, token)
      .then((res) => success(res, dispatch))
      .catch((err) => catchError(err, dispatch));
  };

  const put = async (endpoint: string, data: any, token: boolean = true) => {
    dispatch(loading());

    return Api.put(endpoint, data, token)
      .then((res) => success(res, dispatch))
      .catch((err) => catchError(err, dispatch));
  };

  const remove = async (endpoint: string, token: boolean = true) => {
    dispatch(loading());

    return Api.delete(endpoint, token)
      .then((res) => success(res, dispatch))
      .catch((err) => catchError(err, dispatch));
  };

  return {
    get,
    post,
    put,
    delete: remove,
  };
}

function success(res: any, dispatch: any) {
  dispatch(loaded());
  return res;
}

function catchError(error: any, dispatch: any) {  
  dispatch(loaded());
  showErrorMessage(error.ui || error, dispatch);
  Api.catchError(error);
}
