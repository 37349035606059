import { IonContent, IonPage, useIonViewDidEnter, IonIcon, useIonViewWillEnter, IonItem, IonInput } from '@ionic/react';
import React, { useState } from 'react';
import './Pin.scss';
import ProgressBar from '../../components/ProgressBar';
import Storage from '../../services/storage';
import { useLocation } from '../../components/Hook/location';
import { useAlert } from '../../components/Hook/alert';
import { useApi } from '../../components/Hook/api';
import { useNetwork } from '../../components/Hook/network';
import { useQueue } from '../../components/Hook/queue';
import { backspace, logInOutline } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useToast } from '../../components/Hook/toast';
import { usePhoto } from '../../components/Hook/photo';

const Pin: React.FC = () => {
  const Api = useApi();
  const history = useHistory();
  const Network = useNetwork();
  const Toast = useToast();
  const Queue = useQueue();
  const Alert = useAlert();
  const Photo = usePhoto();
  const { network }: any = useSelector<any>((state) => state.Device);
  const Location = useLocation();
  const [pin, setPin]: any[] = useState([]);
  const [clicked, setClicked] = useState(-1);
  const pad = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [jobName, setJobName] = useState('');

  useIonViewDidEnter(() => {
    Storage.get('job_name').then((name) => {
      if (!name) {
        history.goBack();
        return;
      }

      setJobName(name);
    });
    Queue.create({ Photo: Photo });
    Network.listen().then(() => {
      Queue.checkStatus();
    });
  });

  useIonViewWillEnter(() => {
    setPin([]);
  });

  const addNumber = (number: number) => {
    setPin([...pin, number]);
    setClicked(number);
  };

  const removeNumber = () => {
    if (hasPin()) {
      pin.pop();
      setPin(pin.slice());
      setClicked(-1);
    }
  };

  const isActive = (number: number) => {
    return clicked === number ? 'active' : '';
  };

  const hasPin = () => {
    return pin.length;
  };

  const showDisabledLocationMessage = () => {
    Alert.show({
      header: 'Location is Disabled',
      message: 'To enable location, go to your Settings',
      backdropDismiss: false,
      buttons: ['Ok'],
    });
  };

  const loginOffline = () => {
    Storage.get('staff').then((data) => {
      const staffList = data || [];
      const staff = staffList.find((s: any) => s.id.toString() === pin.join(''));

      if (staff) {
        Storage.set('user', staff);
        history.push('/photo');
        return;
      }

      return Toast.error('Pin incorrect');
    });
  };

  const loginOnline = (jobId: any) => {
    Api.post('auth', {
      job: jobId,
      pin: pin.join(''),
    }).then((res) => {
      if (res.success) {
        Storage.set('user', { ...res.data, pin: pin.join('') }).then(() => {
          history.push('/photo');
        });
      }
    });
  };

  const login = () => {
    if (!hasPin()) {
      return;
    }

    if (pin.join('') === '0000') {
      logoutJob(() => {
        history.goBack();
      });
      return;
    }

    Storage.get('job_id').then((jobId) => {
      if (network.connected) {
        loginOnline(jobId);
        return;
      }

      loginOffline();
    });

    // Location.isLocationEnabled()
    //   .then(() => {

    //   })
    //   .catch(() => {
    //     showDisabledLocationMessage();
    //   });
  };

  const logoutJob = (callback?: any) => {
    Alert.show({
      header: 'Logout Confirm',
      message: 'Do you want to logout?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: () => {
            Storage.remove('token');
            Storage.remove('job_id');
            Storage.remove('staff');
            Storage.remove('job_name');

            if (callback) {
              callback();
            }
          },
        },
      ],
    });
  };

  return (
    <IonPage>
      <IonContent class="pin-page">
        <div className="bg-layer"></div>
        <div className="bg-black"></div>
        <ProgressBar></ProgressBar>

        <form className="login">
          <h3>{jobName}</h3>

          <IonItem class="ion-no-padding" lines="none">
            <IonInput class="ion-text-center" value={pin.join('')} type="text" readonly></IonInput>
          </IonItem>
        </form>

        <div className="pad" id="swipe">
          {pad.map((number: number, index: number) => (
            <span key={index} className={`pad-${number}`} onClick={() => addNumber(number)}>
              <span className={isActive(number)}>
                {number}
                <span className="animation"></span>
              </span>
            </span>
          ))}

          <span onClick={() => removeNumber()}>
            <span className={isActive(-1)}>
              <IonIcon className={hasPin() ? '' : 'ion-hide'} icon={backspace}></IonIcon>
            </span>
          </span>

          <span onClick={() => addNumber(0)} className="pad-0">
            <span className={isActive(0)}>
              0<span className="animation"></span>
            </span>
          </span>

          <span onClick={() => login()}>
            <span className={isActive(10)}>
              <IonIcon className={hasPin() ? '' : 'ion-hide'} icon={logInOutline}></IonIcon>
            </span>
          </span>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Pin;
