type Toast = {
  id: number;
  isOpen: boolean;
  message: string;
  color: string;
  duration: number;
};

const initialState = {
  list: []
};

export default function(state = initialState, action: any) {
  switch (action.type) {
    case 'SHOW_TOAST':
      const list: Toast[] = state.list;
      const newToast = {
        id: Date.now(),
        isOpen: true,
        message: action.message,
        color: action.color,
        duration: action.duration || 2000
      };

      list.push(newToast);

      return { list };
    case 'HIDE_TOAST':
      return {
        list: state.list.filter((toast: Toast) => {
          return toast.id !== action.id;
        })
      };
    default:
      return state;
  }
}
