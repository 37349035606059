import { SQLite, SQLiteObject, SQLiteOriginal } from '@ionic-native/sqlite';
import { Plugins } from '@capacitor/core';
import Storage from './storage';

const { Device } = Plugins;

interface Queue {
  id: number;
  type: string;
  method: string;
  data: string;
  status: string;
  success: string;
  failed: string;
  timestamp?: string;
}

export class QueueSqLite {
  public stopped: boolean;
  public queue: Queue | any;
  public provider: any;
  public running: boolean;
  public available: boolean;
  public platform: string;
  private storage: SQLiteOriginal;

  constructor() {
    this.stopped = false;
    this.running = false;
    this.queue = null;
    this.storage = SQLite;
    this.available = false;
    this.platform = '';
  }

  public async checkDatabase() {
    const info = await Device.getInfo();

    if (info.platform === 'ios' || info.platform === 'android') {
      this.platform = 'native';
    } else {
      this.platform = 'web';
      return;
    }

    return this.storage
      .echoTest()
      .then(() => {
        this.available = true;
      })
      .catch(() => {
        this.available = false;
        console.log('Queue not available');
      });
  }

  public create(provider: any, success?: any, failed?: any) {
    this.provider = provider;
    const createTable = `CREATE TABLE IF NOT EXISTS queue(
        id INTEGER PRIMARY KEY NOT NULL,
        type VARCHAR(30),
        method VARCHAR(255),
        data VARCHAR(255),
        status VARCHAR(30),
        success VARCHAR(255),
        failed VARCHAR(255)
      )`;
    return this.executeSql(createTable, [], success, failed);
  }

  public push(data: any[], success?: any, failed?: any) {
    return this.executeSql(
      'INSERT INTO queue VALUES(?,?,?,?,?,?,?)',
      data,
      success,
      failed
    );
  }

  public pop(id: number, success?: any, failed?: any) {
    return this.executeSql(
      'DELETE FROM queue WHERE id = ?',
      [id],
      success,
      failed
    );
  }

  public updateStatus(status: string, id: number, success?: any, failed?: any) {
    return this.executeSql(
      'UPDATE queue SET status = ? WHERE id = ?',
      [status, id],
      success,
      failed
    );
  }

  public async start() {
    if (this.stopped) {
      return;
    }

    return this.executeSql('SELECT * FROM queue', [], (res) => {
      if (res.rows.length === 0) {
        console.log('Queue empty');
        return;
      }

      for (let index = 0; index < res.rows.length; index++) {
        let queue = null;

        if (this.platform === 'native') {
          queue = res.rows.item(index);
        } else {
          queue = res.rows[index];
        }

        switch (queue.status) {
          case 'processing':
            if (!this.running) {
              this.running = true;
              this.queue = queue;
              this.sync();
            }
            return;
          case 'pending':
            this.running = true;
            this.queue = queue;
            this.sync();
            return;
          default:
            break;
        }
      }
    });
  }

  public sync() {
    return this.updateStatus(
      'processing',
      this.queue.id,
      (res: any) => {
        const method = this.queue.method.split('.');

        if (method.length === 2) {
          this.provider[method[0]][method[1]](this.queue.data);
        }
      },
      (err: any) => console.log(err)
    );
  }

  public success() {
    this.pop(this.queue.id, () => {
      const method = this.queue.success.split('.');

      this.start();

      if (method.length === 2) {
        this.provider[method[0]][method[1]](this.queue.data);
      }
    });
  }

  public failed() {
    this.updateStatus('failed', this.queue.id, () => {
      const method = this.queue.failed.split('.');

      this.start();

      if (method.length === 2) {
        this.provider[method[0]][method[1]](this.queue.data);
      }
    });
  }

  private async executeSql(
    query: string,
    params: any[] = [],
    success = (res: any) => {},
    failed = (err: any) => {}
  ) {
    if (!this.platform) {
      await this.checkDatabase();
    }

    if (this.platform === 'web') {
      return this.executeSqlWeb(query, params, success, failed);
    }

    if (this.available === false) {
      return;
    }

    this.storage
      .create({
        name: 'clocker.db',
        location: 'default',
      })
      .then((db: SQLiteObject) => {
        db.executeSql(query, params)
          .then((res: any) => {
            success(res);
          })
          .catch((err: any) => {
            failed(err);
          });
      })
      .catch((err: any) => {
        failed(err);
      });
  }

  private async executeSqlWeb(
    query: string,
    params: any[] = [],
    success = (res: any) => {},
    failed = (err: any) => {}
  ) {
    if (this.platform !== 'web') {
      return;
    }

    const typeSql = query.split(' ')[0];

    switch (typeSql) {
      case 'CREATE':
        return this.createWebDB()
          .then((res) => success(res))
          .catch((err) => failed(err));
      case 'SELECT':
        return this.selectWebDB()
          .then((res) => success(res))
          .catch((err) => failed(err));
      case 'INSERT':
        return this.insertWebDB(params)
          .then((res) => success(res))
          .catch((err) => failed(err));
      case 'UPDATE':
        return this.updateWebDB(params)
          .then((res) => success(res))
          .catch((err) => failed(err));
      case 'DELETE':
        return this.deleteWebDB(params)
          .then((res) => success(res))
          .catch((err) => failed(err));
      default:
        return success({});
    }
  }

  private async createWebDB() {
    const queue = await Storage.get('clocker.db');

    if (!queue) {
      await Storage.set('clocker.db', []);
    }

    console.log(queue);

    return;
  }

  private async selectWebDB() {
    let queue = await Storage.get('clocker.db');

    if (!queue) {
      queue = [];
    }

    return {
      rows: queue,
    };
  }

  private async insertWebDB(params: any[] = []) {
    const data: Queue = {
      id: params[0],
      type: params[1],
      method: params[2],
      data: params[3],
      status: params[4],
      success: params[5],
      failed: params[6],
    };

    let queue = await Storage.get('clocker.db');

    if (!queue) {
      queue = [];
    }

    queue.push(data);

    await Storage.set('clocker.db', queue);
  }

  private async updateWebDB(params: any[] = []) {
    const queue: Queue[] = await Storage.get('clocker.db');

    if (!queue || queue.length === 0) {
      return;
    }

    const id = params[1];
    const status = params[0];
    const row = queue.find((r) => r.id === id);

    if (!row) {
      return;
    }

    row.status = status;

    await Storage.set('clocker.db', queue);

    return;
  }

  private async deleteWebDB(params: any[] = []) {
    const queue: Queue[] = await Storage.get('clocker.db');

    if (!queue || queue.length === 0) {
      return;
    }

    const id = params[0];

    await Storage.set(
      'clocker.db',
      queue.filter((row) => {
        return row.id !== id;
      })
    );

    return;
  }
}
