type ToastOptions = {
  message: string;
  color: string;
  duration?: number;
};

export const showToast = (options: ToastOptions) => {
  return { type: 'SHOW_TOAST', ...options };
};

export const hideToast = (id: number) => {
  return { type: 'HIDE_TOAST', id };
};
