import { useMoment } from './moment';
import { useQueue } from './queue';
import { useApi } from './api';
import { useFilesystem } from '@ionic/react-hooks/filesystem';
import { FilesystemDirectory } from '@capacitor/core';

export function usePhoto() {
  const moment: any = useMoment();
  const Queue = useQueue();
  const Api = useApi();
  const { readFile } = useFilesystem();

  const postInfo = (data: any) => {
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }

    Api.post('photo', data)
      .then((res) => {
        Queue.db.push(
          [
            moment().valueOf(),
            'image',
            'Photo.postImage',
            JSON.stringify({
              id: res.id,
              image: data.image,
            }),
            'pending',
            'Photo.success',
            'Photo.postImage',
          ],
          () => Queue.db.success()
        );
      })
      .catch(() => {
        Queue.db.failed();
      });
  };

  const postImage = async (data: any) => {
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }

    const file = await readFile({
      path: data.image,
      directory: FilesystemDirectory.Data,
    });

    Api.put('photo/' + data.id, {
      image: `data:image/jpeg;base64,${file.data}`,
    })
      .then(() => {
        Queue.db.success();
      })
      .catch(() => {
        Queue.db.failed();
      });
  };

  return { postInfo, postImage };
}
