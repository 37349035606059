import axios from 'axios';
import Storage from './storage';

const ENV = process.env.REACT_APP_ENV || process.env.NODE_ENV;
const API = ENV === 'production' ? process.env.REACT_APP_API_PRODUCTION : process.env.REACT_APP_API;

function getHeaders(init: any = {}) {
  return {
    ...init,
  };
}

async function appendTokenToHeader(headers: any) {
  const token = await Storage.get('token');

  headers['Authorization'] = token;
}

async function get(endpoint: string, token: boolean = true) {
  const headers = getHeaders();

  if (token) {
    await appendTokenToHeader(headers);
  }

  return axios
    .get(API + endpoint, {
      method: 'GET',
      headers: headers,
    })
    .then((res) => res.data)
    .catch((error) => catchError(error));
}

async function post(endpoint: string, data: any, token: boolean = true) {
  const headers = getHeaders();

  if (token) {
    await appendTokenToHeader(headers);
  }

  return axios
    .post(API + endpoint, data, {
      method: 'POST',
      headers,
    })
    .then((res) => res.data)
    .catch((error) => catchError(error));
}

async function put(endpoint: string, data: any, token: boolean = true) {
  const headers = getHeaders();

  if (token) {
    await appendTokenToHeader(headers);
  }

  return axios
    .put(API + endpoint, data, {
      method: 'PUT',
      headers,
    })
    .then((res) => res.data)
    .catch((error) => catchError(error));
}

async function remove(endpoint: string, token: boolean = true) {
  const headers = getHeaders();

  if (token) {
    await appendTokenToHeader(headers);
  }

  return axios
    .delete(API + endpoint, {
      method: 'DELETE',
      headers,
    })
    .then((res) => res.data)
    .catch((error) => catchError(error));
}

function catchError(error: any) {
  if (error.response && error.response.data) {
    throw error.response.data.error || error.response.data.message;
  }

  if (error.message) {
    throw error.message;
  }

  throw error;
}

export { get, post, put, remove as delete, catchError };
