export default {
  vi: 'Tiếng việt',
  en: 'Tiếng anh',
  search: {
    title: 'Tìm kiếm',
    job: 'Tìm kiếm công việc'
  },
  filter: {
    title: 'Lọc',
    job: 'Lọc công việc'
  },
  postJob: 'ĐĂNG TIN TUYỂN DỤNG'
};
