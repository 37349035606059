import { useDispatch, useStore } from 'react-redux';
import { changeQueueStatus, DeviceStore } from '../../Action/device';
import { QueueSqLite } from '../../services/queueSqLite';

export function useQueue() {
  const dispatch = useDispatch();
  const Store = useStore();

  const checkStatus = () => {
    const Device = Store.getState().Device;

    if (!Device.network.connected) {
      dispatch(changeQueueStatus({ stopped: true }));
      return;
    }

    dispatch(changeQueueStatus({ stopped: false }));
    start();
  };

  const create = (provider: any) => {
    const Device = Store.getState().Device;
    return createSQLiteDatabase(provider, Device.sqLite);
  };

  const start = () => {
    const Device: DeviceStore = Store.getState().Device;

    return Device.sqLite.start();
  };

  const push = (data: any[], success?: any, failed?: any) => {
    const Device: DeviceStore = Store.getState().Device;

    return Device.sqLite.push(data, success, failed);
  };

  const success = () => {
    const Device: DeviceStore = Store.getState().Device;

    return Device.sqLite.success();
  };

  const failed = () => {
    const Device: DeviceStore = Store.getState().Device;

    return Device.sqLite.failed();
  };

  return { checkStatus, create, db: { push, start, success, failed } };
}

function createSQLiteDatabase(provider: any, sqlite: QueueSqLite) {
  console.log('Starting queue ...');

  return sqlite.create(
    provider,
    (res: any) => {
      console.log('Start queue successfully');
    },
    (err: any) => {
      console.log('Start queue failed: ', err);
    }
  );
}
