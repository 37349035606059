import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  IonPage,
  IonContent,
  useIonViewDidEnter,
  IonIcon,
  IonFab,
  IonFabButton,
  IonItem,
  IonThumbnail,
  IonLabel,
  IonCard,
} from '@ionic/react';
import Navigation from '../../components/Navigation/Navigation';
import ProgressBar from '../../components/ProgressBar';
import { cameraReverseOutline, checkmarkCircleOutline, appsOutline } from 'ionicons/icons';
import { useAlert } from '../../components/Hook/alert';
import Storage from '../../services/storage';
import { useHistory } from 'react-router';
import { useMoment } from '../../components/Hook/moment';
import { useCamera } from '../../components/Hook/camera';
import 'moment-timezone';
import './Photo.scss';
import { useSelector } from 'react-redux';
import { useLocation } from '../../components/Hook/location';
import { useNetwork } from '../../components/Hook/network';
import { useQueue } from '../../components/Hook/queue';
import { useToast } from '../../components/Hook/toast';
import { useFilesystem } from '@ionic/react-hooks/filesystem';
import { FilesystemDirectory } from '@capacitor/core';
import CropperImage from '../../components/Cropper/Cropper';

const Photo: React.FC = () => {
  const Alert = useAlert();
  const Toast = useToast();
  const history = useHistory();
  const moment: any = useMoment();
  const Network = useNetwork();
  const Queue = useQueue();
  const Camera = useCamera();
  const Location = useLocation();
  const { network }: any = useSelector<any>((state) => state.Device);
  const [user, setUser]: any = useState({});
  const [shift, setShift]: any = useState({});
  const [time, setTime]: any = useState();
  const [imageData, setImageData]: any = useState();
  const [imagePath, setImagePath]: any = useState();
  const [jobId, setJobId]: any = useState();
  const [save, setSave]: any = useState(false);
  const [openCrop, setOpenCrop]: any = useState(false);
  const { writeFile } = useFilesystem();
  const cameraInput: any = useRef(null);
  const [cropImageData, setCropImageData]: any = useState();

  const logoutJob = (callback?: any) => {
    Storage.remove('user');

    if (callback) {
      callback();
    }
  };

  const hasEntry = () => {
    Alert.show({
      header: 'Has Entry',
      message: `You have start and end time for this shift already.
       Please contact a booking manager if this is not correct`,
      backdropDismiss: false,
      buttons: [
        {
          text: 'BACK',
          role: 'OK',
          handler: () => {
            history.goBack();
          },
        },
      ],
    });
  };

  const takePhoto = async (e?: any) => {
    Camera.isCameraEnabled()
      .then((info: any) => {
        setOpenCrop(true);
        return;
        // if (typeof info === 'object' && info.model === 'iPhone' && info.operatingSystem === 'ios') {
        //   cameraInput.current.click();
        //   return;
        // }

        // Camera.takePicture({ resultType: 'uri' })
        //   .then(async (data) => {
        //     setCropImageData(data.webPath);
        //     setOpenCrop(true);
        //   })
        //   .catch((err) => {
        //     if (err !== 'User cancelled photos app') {
        //       Camera.permissionDenied();
        //     }
        //   });
      })
      .catch((err) => {
        Camera.permissionDenied(err.message);
      });
  };

  const uploadImage = () => {
    if (!network.connected) {
      pushToQueue();
      return;
    }

    pushToQueue();

    // Location.getCurrentPosition()
    //   .then(async (value) => {
    //     pushToQueue(value);
    //   })
    //   .catch((err) => {
    //     Alert.disabledLocationMessage(err.message);
    //   });
  };

  const pushToQueue = (value?: any) => {
    const data = {
      image: imageData,
      location_lat: value ? value.coords.latitude : null,
      location_long: value ? value.coords.longitude : null,
      time: Math.round(moment().valueOf() / 1000),
      date: moment().tz('Europe/London').format('DD/MM/YYYY'),
      staff_id: user.pin,
      job_id: jobId,
      shift_id: shift.id,
    };

    Queue.db.push(
      [moment().valueOf(), 'log', 'Photo.postInfo', JSON.stringify(data), 'pending', 'Photo.success', 'Photo.failed'],
      () => {
        Queue.db.start();
        Storage.remove('user');
        history.goBack();
        Toast.success('Added successfully');
        setImageData(null);
        setImagePath(null);
        setSave(false);
      },
      () => {
        Toast.error('Added failed');
        setSave(false);
      },
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().tz('Europe/London').format('HH:mm'));
    }, 1000);

    return () => clearInterval(interval);
  }, [setTime, moment]);

  useIonViewDidEnter(() => {
    Network.listen().then(() => {
      Queue.checkStatus();
    });

    Storage.get('user').then((data) => {
      if (!data) {
        history.goBack();
        return;
      }

      if (data.finish) {
        hasEntry();
        return;
      }

      setUser(data);
      setShift(data.shift || {});
    });

    Storage.get('job_id').then((id) => {
      setJobId(id);
    });

    setTime(moment().tz('Europe/London').format('HH:mm'));
  });

  const checkTypeClock = (type: string) => {
    if (user.clock_in && type === 'in') {
      return;
    }

    if (!imagePath) {
      takePhoto();
    }
  };

  const ChangeColorIonItem = (type: string) => {
    if (user.clock_in && type === 'in') {
      return 'medium';
    }

    if (imagePath) {
      return 'success';
    }

    return;
  };

  const readImage = (file: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', async (event: any) => {
      setCropImageData(event.target.result);
      setOpenCrop(true);
    });

    reader.readAsDataURL(file);
  };

  const cropImage = async (data: any) => {
    const fileName = 'Photo-' + moment().valueOf() + '.jpeg';
    const base64Data = data;

    await writeFile({
      path: fileName,
      data: base64Data,
      directory: FilesystemDirectory.Data,
    });

    setImageData(fileName);
    setImagePath(data);
  };

  const hideCropModal: any = useCallback(() => {
    setOpenCrop(false);
  }, []);

  return (
    <IonPage>
      <Navigation backIcon={appsOutline} defaultHref="pin" confirmBack={logoutJob}>
        <IonLabel className="ion-padding-start ion-text-uppercase" slot="start">
          {user.name}
        </IonLabel>
        <IonLabel slot="end">{user.id}</IonLabel>
      </Navigation>

      <IonContent class="photo-page">
        <ProgressBar></ProgressBar>
        <div className="bg-layer"></div>
        <div className="bg-black"></div>

        <div className="body">
          <div>
            {shift.start_time ? (
              <div className="time">
                <p>{user.clock_in ? 'SHIFT END' : 'SHIFT START'}</p>
                <span>{user.clock_in ? shift.end_time : shift.start_time}</span>
              </div>
            ) : undefined}

            <IonCard>
              <IonItem color={ChangeColorIonItem('in')} onClick={() => checkTypeClock('in')}>
                <IonThumbnail>
                  <img src={user.clock_in ? user.clock_in.image : imagePath || 'assets/images/default-avatar.jpg'} alt="" />
                </IonThumbnail>
                <IonLabel>{user.clock_in ? 'CLOCKED IN' : 'CLOCK IN'}</IonLabel>
                <span slot="end">{user.clock_in ? user.clock_in.time : time}</span>
              </IonItem>
            </IonCard>

            {user.clock_in ? (
              <IonCard>
                <IonItem color={ChangeColorIonItem('out')} onClick={() => checkTypeClock('out')}>
                  <IonThumbnail>
                    <img src={imagePath || 'assets/images/default-avatar.jpg'} alt="" />
                  </IonThumbnail>
                  <IonLabel>CLOCK OUT</IonLabel>
                  <div slot="end" className="time-now">
                    <span>{time}</span>
                  </div>
                </IonItem>
              </IonCard>
            ) : null}

            {user.clock_in || !shift.end_time ? null : (
              <div className="shift-end">
                <p>SHIFT END</p>
                <span>{shift.end_time}</span>
              </div>
            )}

            <input
              ref={cameraInput}
              type="file"
              className="ion-hide"
              accept="image/*"
              capture="camera"
              onChange={(e: any) => readImage(e.target.files[0])}
            />
          </div>
        </div>

        <IonFab vertical="bottom" horizontal="start" slot="fixed" className={imageData ? '' : 'ion-hide'}>
          <IonFabButton color="secondary" onClick={() => takePhoto()}>
            <IonIcon color="light" icon={cameraReverseOutline} />
          </IonFabButton>
        </IonFab>

        <IonFab vertical="bottom" horizontal="end" slot="fixed" className={imageData ? '' : 'ion-hide'}>
          <IonFabButton color="success" onClick={() => uploadImage()} disabled={save}>
            <IonIcon icon={checkmarkCircleOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>

      <CropperImage
        openCrop={openCrop}
        imagePath={cropImageData}
        cropImage={(data) => cropImage(data)}
        dismiss={hideCropModal}
      ></CropperImage>
    </IonPage>
  );
};

export default Photo;
