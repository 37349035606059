export default {
  vi: 'Vietnamese',
  en: 'English',
  search: {
    title: 'Search',
    job: 'Search jobs'
  },
  filter: {
    title: 'Filter',
    job: 'Filter jobs'
  },
  postJob: 'POST A JOB'
};
