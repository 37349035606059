import { IonHeader, IonToolbar, IonIcon, IonButton, IonButtons, IonTitle, IonBackButton } from '@ionic/react';
import React, { useRef } from 'react';
import { close } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import './Navigation.scss';

type NavigationType = {
  label?: any;
  backIcon?: any;
  backBtnSlot?: any;
  defaultHref?: string;
  confirmBack?: any;
};

const Navigation: React.FC<NavigationType> = (props) => {
  const { t } = useTranslation();
  const backButton: any = useRef(null);

  const back = (e: any) => {
    e.persist();

    if (props.confirmBack) {
      props.confirmBack(() => {
        backButton.current.clickButton(e);
      });

      return;
    }

    backButton.current.clickButton(e);
  };

  return (
    <IonHeader>
      <IonToolbar color="medium">
        {props.children}

        <IonTitle>{t(props.label)}</IonTitle>

        <IonButtons slot={props.backBtnSlot || 'end'}>
          <IonButton class="ion-no-padding" onClick={(e) => back(e)}>
            <IonIcon slot="icon-only" icon={props.backIcon || close} />
          </IonButton>
          <IonBackButton ref={backButton} class="ion-hide" defaultHref={props.defaultHref || '/'} />
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Navigation;
