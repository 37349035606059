import React, { useState } from 'react';
import {
  IonPage,
  IonContent,
  useIonViewDidEnter,
  IonHeader,
  IonItem,
  IonInput,
  IonImg,
  IonRow,
  IonCol,
  IonButtons,
  IonButton,
  IonIcon,
  useIonViewWillEnter,
} from '@ionic/react';
import './Auth.scss';
import { useApi } from '../../components/Hook/api';
import { useToast } from '../../components/Hook/toast';
import { useAlert } from '../../components/Hook/alert';
import { useLocation } from '../../components/Hook/location';
import Storage from '../../services/storage';
import { useHistory } from 'react-router';
import ProgressBar from '../../components/ProgressBar';
import { logInOutline } from 'ionicons/icons';

const Auth: React.FC = () => {
  const history = useHistory();
  const Api = useApi();
  const Toast = useToast();
  const Alert = useAlert();
  const Location = useLocation();
  const [password, setPassword] = useState<any>();

  useIonViewDidEnter(() => {
    Storage.get('token').then((token) => {
      if (token) {
        history.push('/pin');
      }
    });
  });

  const login = (data: any) => {
    Api.post('auth', { job: data }).then((res) => {
      Storage.set('token', res.data.token);
      Storage.set('job_id', res.data.job_id);
      Storage.set('staff', res.data.staff);
      Storage.set('job_name', res.data.job_name);
      history.push('/pin');
    });
    // checkLocation().then(() => {

    // });
  };

  const showDisabledLocationMessage = () => {
    Alert.show({
      header: 'Location is Disabled',
      message: 'To enable location, go to your Settings',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          handler: () => {},
        },
      ],
    });
  };

  const checkLocation = async () => {
    return Location.isLocationEnabled()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        showDisabledLocationMessage();
        throw err;
      });
  };

  useIonViewDidEnter(() => {
    // checkLocation();
  });

  const submitLogin = (e: any) => {
    e.preventDefault();

    if (!password) {
      Toast.error('Enter a Job Password');
      return false;
    }

    login(password);
    setPassword(null);
  };

  return (
    <IonPage>
      <IonHeader></IonHeader>
      <IonContent class="auth-page">
        <div className="bg-layer"></div>
        <div className="bg-black"></div>
        <ProgressBar></ProgressBar>

        <IonRow class="login-wrapper">
          <IonCol size="12" sizeSm="6" sizeXl="4">
            <form className="login" onSubmit={(e: any) => submitLogin(e)}>
              <IonImg src="../../../assets/white-group-logo.svg" />

              <p>
                Please enter a job password to unlock. If you do not know your job password please contact your manager or
                the Stambridge Office.
              </p>

              <IonItem class="ion-no-padding" lines="none">
                <IonInput
                  class="login-input"
                  value={password}
                  type="password"
                  onIonChange={(e: any) => setPassword(e.detail.value)}
                ></IonInput>

                <IonButtons>
                  <IonButton type="submit">
                    <IonIcon slot="icon-only" icon={logInOutline}></IonIcon>
                  </IonButton>
                </IonButtons>
              </IonItem>
            </form>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Auth;
