import React from 'react';
import { IonProgressBar } from '@ionic/react';
import { useSelector } from 'react-redux';
import './ProgressBar.scss';

const ProgressBar: React.FC = () => {
  const { isLoading }: any = useSelector<any>((state) => state.Api);

  return (
    <div className={isLoading ? 'progress' : 'progress  ion-hide'}>
      <IonProgressBar color="primary" type="indeterminate"></IonProgressBar>
    </div>
  );
};

export default ProgressBar;
