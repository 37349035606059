import {
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButton,
  IonButtons,
  IonTitle,
  IonModal,
  IonContent,
  IonFooter,
  IonFab,
  IonFabButton,
} from '@ionic/react';
import React, { useRef, useState } from 'react';
import {
  close,
  checkmarkCircleOutline,
  addCircleOutline,
  removeCircleOutline,
  arrowForwardOutline,
  arrowBackOutline,
  arrowUpOutline,
  arrowDownOutline,
  syncOutline,
  scanOutline,
} from 'ionicons/icons';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './Cropper.scss';
import { useCamera } from '../Hook/camera';

type CropperType = {
  openCrop: boolean;
  imagePath?: any;
  cropImage: (data: any) => {};
  dismiss: () => {};
};

const CropperImage: React.FC<CropperType> = (props) => {
  const refCropImage: any = useRef(null);
  const refModal: any = useRef(null);
  const [height, setHeight] = useState(300);
  const [width, setWidth] = useState(600);
  const [show, setShow] = useState(false);
  const [imageData, setImageData]: any = useState(null);
  const [stream, setStream]: any = useState(null);
  const Camera = useCamera();

  const cropImage = () => {
    props.cropImage(refCropImage.current.getCroppedCanvas().toDataURL());
    refCropImage.current.clear();
    refModal.current.dismiss();
  };

  const RotateImage = (type: string) => {
    refCropImage.current.cropper.rotate(type === 'forward' ? 45 : -45);
  };

  const zoomImage = (type: string) => {
    refCropImage.current.cropper.zoom(type === 'in' ? 0.5 : -0.5);
  };

  const moveImage = (x: number, y: number) => {
    refCropImage.current.cropper.move(x, y);
  };

  const setConfig = () => {
    setWidth(window.document.getElementById('cropper-modal')?.offsetWidth || width);
    setHeight(window.document.getElementById('cropper-modal')?.offsetHeight || height);

    const constraints = {
      audio: false,
      video: true
    };

    try {
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        const video: any = document.querySelector('video');
        video.srcObject = stream;
        setStream(stream);
      }).catch((error) => {
        Camera.permissionDenied(error.message);
      });
    } catch (error) {
      Camera.permissionDenied(error.message);
    }
  };

  const takePictureFromVideo = () => {
    const video: any = document.querySelector('video');
    const canvas: any = document.querySelector('canvas');

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

    setImageData(canvas.toDataURL(), 0.5);
    setShow(true);

    setTimeout(() => {
      cropImage();
    }, 300);
  }

  const dismissModal = () => {
    setShow(false);
    setImageData(null);
    setStream(null);

    props.dismiss();

    if (!stream) {
      return;
    }

    const tracks = stream.getTracks();

    tracks.forEach(function(track: any) {
      track.stop();
    });
  };

  return (
    <IonModal
      ref={refModal}
      isOpen={props.openCrop}
      onDidDismiss={() => dismissModal()}
      onDidPresent={() => setConfig()}
    >
      <IonHeader>
        <IonToolbar color="medium">
          <IonTitle>Take picture</IonTitle>
          <IonButtons slot="end" onClick={() => refModal.current.dismiss()}>
            <IonButton>
              <IonIcon slot="icon-only" icon={close}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="cropper-modal">
        {show ? (
          <Cropper
            ref={refCropImage}
            src={imageData}
            minContainerWidth={width}
            minContainerHeight={height}
            aspectRatio={1 / 1}
            autoCropArea={1}
            center={true}
            className="crop"
          />
        ) : null}

        <video className={show ? 'ion-hide' : ''} playsInline autoPlay></video>
        <canvas className="ion-hide"></canvas>

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton color="medium" onClick={() => takePictureFromVideo()} disabled={show}>
            <IonIcon icon={scanOutline} />
          </IonFabButton>
        </IonFab>
      </IonContent>

      <IonFooter className="ion-hide">
        <IonToolbar color="medium">
          <IonButtons slot="start" onClick={() => zoomImage('out')}>
            <IonButton>
              <IonIcon slot="icon-only" icon={removeCircleOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="start" onClick={() => zoomImage('in')}>
            <IonButton>
              <IonIcon slot="icon-only" icon={addCircleOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="start" onClick={() => moveImage(-15, 0)}>
            <IonButton>
              <IonIcon slot="icon-only" icon={arrowBackOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="start" onClick={() => moveImage(15, 0)}>
            <IonButton>
              <IonIcon slot="icon-only" icon={arrowForwardOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="start" onClick={() => moveImage(0, -15)}>
            <IonButton>
              <IonIcon slot="icon-only" icon={arrowUpOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="start" onClick={() => moveImage(0, 15)}>
            <IonButton>
              <IonIcon slot="icon-only" icon={arrowDownOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="start" onClick={() => RotateImage('forward')}>
            <IonButton>
              <IonIcon slot="icon-only" icon={syncOutline}></IonIcon>
            </IonButton>
          </IonButtons>

          <IonButtons slot="end">
            <IonButton color="success" onClick={() => cropImage()}>
              <IonIcon slot="icon-only" icon={checkmarkCircleOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};

export default CropperImage;
