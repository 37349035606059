import { showToast } from './toast';

export const loading = () => {
  return {
    type: 'LOADING'
  };
};

export const loaded = () => {
  return {
    type: 'LOADED'
  };
};

export function showErrorMessage(errorMessage: any, dispatch: any) {
  return dispatch(
    showToast({
      message: errorMessage,
      color: 'danger'
    })
  );
}
