import { Plugins } from '@capacitor/core';
import { useDispatch, useStore } from 'react-redux';
import { changeNetworkStatus } from '../../Action/device';
import { useQueue } from './queue';

const { Network } = Plugins;

export function useNetwork() {
  const dispatch = useDispatch();
  const Queue = useQueue();
  const Store = useStore();

  const listen = async () => {
    const Device = Store.getState().Device;

    if (Device.network.listener) {
      return;
    }

    let listener = Network.addListener('networkStatusChange', (status) => {
      console.log('Network status changed', status);
      dispatch(changeNetworkStatus(status));
      Queue.checkStatus();
    });

    dispatch(changeNetworkStatus({ listener }));

    return await Network.getStatus().then(status => {
      dispatch(changeNetworkStatus(status));
    })
  };

  const removeListener = () => {
    const Device = Store.getState().Device;

    if (!Device.network.listener) {
      return;
    }

    Device.network.listener.remove();
  }

  return { Network, listen, removeListener };
}
